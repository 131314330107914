import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { navigate } from 'gatsby';
import './index.scss';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import fieldMasks from '../../helpers/fieldMasks';
import { InputText } from '../../components/Argo-ui';
import Button from '../../components/button/button';
import MainFooter from '../../components/main-footer/main-footer';
import { getDadosEmpresa } from '../../services/base-service';
import { DataContext } from '../../data/DataProvider';
import { validCNPJ, clearCNPJ } from '../../helpers/validation';
import DefaultLayout from '../../components/layouts/default-layout';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Dropdown from '../../components/dropdown/dropdown';
import LoadingOverlay from '../../components/loading-overlay/loading-overlay';

const block = 'page-cnpj';
const Page = ({ location }) => {
  const [cnpj, setCnpj] = useState();
  const [lockNavigation, setLockNavigation] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Novo estado para o loading
  const { setModal, quotation, setQuotation, brokerData } =
    useContext(DataContext);
  const [lockBroker, setLockBroker] = useState(true);

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
  }, [quotation]);

  const next = useCallback(() => {
    if (cnpj && cnpj.trim().length === 18) {
      const unmaskedCnpj = clearCNPJ(cnpj);
      const cnpjCheck = validCNPJ(unmaskedCnpj);

      if (!cnpjCheck) {
        trackCustomEvent({
          category: 'CNPJ inválido',
          action: '',
          label: unmaskedCnpj,
        });
        setModal({
          values: {
            headline: 'Ops!',
            message: (
              <span>
                Parece que o CNPJ&nbsp;<strong>{cnpj}</strong>&nbsp;não é
                válido, verifique e tente novamente.
              </span>
            ),
            primaryButton: {
              label: 'Ok',
              handler: () => {},
            },
            dismissible: true,
          },
        });
        return;
      }

      // Disable controls(input/button)
      setLockNavigation(true);
      setIsLoading(true);

      if (lockNavigation) {
        setModal({
          values: {
            headline: 'Aguarde um instante...',
            message:
              'Estamos tentando recuperar os dados do CNPJ informado, aguarde ou preencha o formulário manualmente.',
            primaryButton: {
              label: 'Preencher dados manualmente',
              handler: () => {
                setQuotation({
                  ...quotation,
                  dadosEmpresa: {
                    cnpj: unmaskedCnpj,
                    enderecoCobrancaMesmoLocalRisco: true,
                  },
                });
                navigate('/dados-da-empresa');
              },
            },
          },
        });
      }

      // Send remote request passing company's CNPJ
      getDadosEmpresa(unmaskedCnpj)
        .then((responseData) => {
          if (responseData) {
            trackCustomEvent({
              category: 'Sucesso na consulta de CNPJ',
              action: '',
              label: unmaskedCnpj,
            });
            const { modal, ...rest } = quotation;

            const updatedData = {
              ...rest,
              dadosEmpresa: {
                cnpj: unmaskedCnpj,
                razaoSocial: responseData?.razaoSocial,
                nomeFantasia: responseData?.nomeFantasia,
                telefonePrincipal: responseData?.contatoTelefone,
                email: responseData?.contatoEmail,
                isCorretoraAkad: responseData?.isCorretoraAkad,
                enderecoLocalRisco: {
                  cep: responseData?.matrizCep,
                  logradouro: responseData?.matrizLogradouro,
                  numero: responseData?.matrizNumero,
                  complemento: responseData?.matrizComplemento,
                  bairro: responseData?.matrizBairro,
                  cidade: responseData?.matrizCidade,
                  estado: responseData?.matrizUf,
                },
                enderecoCobranca: {
                  cep: responseData?.matrizCep,
                  logradouro: responseData?.matrizLogradouro,
                  numero: responseData?.matrizNumero,
                  complemento: responseData?.matrizComplemento,
                  bairro: responseData?.matrizBairro,
                  cidade: responseData?.matrizCidade,
                  estado: responseData?.matrizUf,
                },
                enderecoCobrancaMesmoLocalRisco: true,
              },
            };

            setQuotation({ ...updatedData });
            navigate('/dados-da-empresa');
          } else {
            trackCustomEvent({
              category: 'Erro na consulta de CNPJ',
              action: '',
              label: unmaskedCnpj,
            });
            setQuotation({
              dadosEmpresa: {
                cnpj: unmaskedCnpj,
                enderecoCobrancaMesmoLocalRisco: true,
              },
            });
            navigate('/dados-da-empresa');
          }
        })
        .finally(() => {
          setLockNavigation(false);
        });
    } else {
      trackCustomEvent({
        category: 'CNPJ em branco',
        action: '',
      });
      setModal({
        values: {
          headline: 'Oops!',
          message: <span>Precisamos do CNPJ do segurado para prosseguir.</span>,
          primaryButton: {
            label: 'Ok',
            handler: () => {},
          },
          dismissible: true,
        },
      });
    }
  }, [quotation, cnpj, setQuotation, lockNavigation, setModal]);

  const brokerList = useMemo(() => {
    if (!brokerData?.dadosBroker?.corretoras) {
      setLockBroker(false);
      return null;
    }

    const brokers = brokerData?.dadosBroker?.corretoras?.map((broker) => ({
      value: clearCNPJ(broker.documento),
      label: `${broker.nome}`,
      email: broker.email,
    }));

    return [...brokers];
  }, [brokerData]);

  const brokerChangeHandler = useCallback(
    (item) => {
      setLockBroker(false);
      setQuotation({
        ...quotation,
        dadosBroker: {
          brokerId: item.value,
          email: item.email,
          nome: item.label,
        },
      });
    },
    [quotation, setQuotation]
  );

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={`container--mobile ${block}`}>
        <MainHeader />

        <PageHeader
          backButtonHandler={() => navigate('/')}
          title="CNPJ da empresa"
          className={`${block}__container`}
          description={
            <div>
              <div>
                Informe o CNPJ da empresa para buscarmos os dados na base do
                governo e agilizar sua vida.
              </div>
              {brokerList && (
                <div>
                  Assessoria, após registrar o CNPJ da empresa, selecione a
                  Corretora responsável por essa cotação.
                </div>
              )}
            </div>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
          ]}
          fixed
          hide={true}
        />
        <div className={`container ${block}--fixed`}>
          {isLoading && <LoadingOverlay />}
          <div className={`${block}__wrapper`}>
            <InputText
              label={'CNPJ'}
              mask={fieldMasks.cnpj}
              name="cnpj"
              placeholder="Informe o CNPJ do local a ser segurado"
              onChange={(fieldValue) => setCnpj(fieldValue.target.value)}
            />
            {brokerList && (
              <div className={`${block}__brokers`}>
                <div className={`${block}__field-label`}>
                  Responsável pela cotação
                </div>
                <Dropdown
                  className={`${block}__brokers-dropdown`}
                  items={brokerList}
                  changeHandler={brokerChangeHandler}
                  placeholder="Selecione a corretora"
                />
              </div>
            )}
            <Button
              className={`${block}__cta`}
              onClick={() => next()}
              disabled={lockNavigation || lockBroker}
            >
              Avançar
            </Button>
          </div>
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
