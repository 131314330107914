import React from 'react';
import './loading-overlay.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const loadingPhrases = [
  "Aguarde enquanto validamos o CNPJ",
  "Preparando uma cotação personalizada para você.",
  "Enquanto você espera, estamos preenchendo sua cotação com cuidado.",
  "Aguarde mais um instante enquanto finalizamos os últimos detalhes."
];

const LoadingOverlay = () => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) =>
        (prevIndex + 1) % loadingPhrases.length
      );
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="loading-overlay">
      <div className="c-loader"></div>
      <div className="loading-phrase">
        {loadingPhrases[currentPhraseIndex]}
      </div>
    </div>
  );
};

export default LoadingOverlay;